import { useMutation, useQuery } from "@apollo/client"
import { Snackbar, FormGroup, SnackbarContent, IconButton } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"
import { useForm } from "react-hook-form"
import { RouteChildrenProps } from "react-router"
import InfoSection from "../components/InfoSection"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_SERVICE_SCALING_QUERY, ADMIN_SET_SERVICE_SCALING_MUTATION } from "../queries"
import { AdminServiceScalingQuery } from "../../../__generated__/AdminServiceScalingQuery"
import { AdminSetServiceScalingMutation, AdminSetServiceScalingMutationVariables } from "../../../__generated__/AdminSetServiceScalingMutation"
import { emptyArray, epochToLocalDatetimeInputValue } from "../../../common/misc-utils"

function ServiceScaling({ location, history }: RouteChildrenProps) {
  // const classes = useStyles()
  const centralGameInstancesQuery = useQuery<AdminServiceScalingQuery>(ADMIN_SERVICE_SCALING_QUERY)
  const [mutate, mutationResult] = useMutation<AdminSetServiceScalingMutation, AdminSetServiceScalingMutationVariables>(
    ADMIN_SET_SERVICE_SCALING_MUTATION,
  )
  const [snackBarSuccessMessage, setSnackBarSuccessMessage] = React.useState("")
  const onClose = () => setSnackBarSuccessMessage("")
  const { register, handleSubmit } = useForm()
  const onSubmit = async (formData) => {
    const oldValues = centralGameInstancesQuery.data?.hostingServiceScalingParemeters || emptyArray
    const newExpiresAt = (formData.expiresAtDate && new Date(formData.expiresAtDate).getTime()) || 1
    const values = oldValues.map((val) => {
      return {
        name: val.name,
        min: (formData[`${val.id}|min`] && parseInt(formData[`${val.id}|min`])) || null,
        max: (formData[`${val.id}|max`] && parseInt(formData[`${val.id}|max`])) || null,
        expiresAt: newExpiresAt,
      }
    })
    await mutate({
      variables: {
        values,
      },
    })
    setSnackBarSuccessMessage("Service parameters saved!")
  }
  const expiresAts = (centralGameInstancesQuery.data?.hostingServiceScalingParemeters || emptyArray).map(({ expiresAt }) => expiresAt || 0)
  const expiresAt = Math.max(0, ...expiresAts)
  const expiresAtDate = (expiresAt && epochToLocalDatetimeInputValue(expiresAt)) || undefined
  return (
    <Layout title={`Ops - Service Scaling`}>
      <InfoSection id="1" heading="About this Tool">
        <p>
          Picks platform has autoscaling built into its archetecture. Even nice features like autoscale on days with games or if enough Entries per
          second are created over 30 seconds.
        </p>
        <p>However, there are times when we would want to manually scale up due to a reason such as:</p>
        <ul>
          <li>A Push notification scheduled to drive traffic to game(s)</li>
          <li>An event like Selection Sunday</li>
          <li>An email campaign</li>
        </ul>
        <p>
          In those instances, we can use this tool to manually scale up infrastructure for a <strong>period of time.</strong>
        </p>
        <p>
          The infrastructure consists of two groups: <strong>web</strong> and <strong>worker</strong> service groups.
          <br />
          Each group can have a <strong>min</strong> and a <strong>max</strong> container value.
        </p>
        <ul>
          <li>
            <strong>min</strong>: lowest amount of containers to be running: garenteeing not to go below that number. This is the most important
            parameter.
          </li>
          <li>
            <strong>max</strong>: highest amount of containers to be running: garenteeing not to go above that number. Less important, but garentees
            we dont bring the platform down by too much traffic to data resources (connection count).
          </li>
        </ul>
        <p>
          <strong>NOTE</strong>: we leave the <code>desiredCount</code> (which sets the value between min and max) to autoscaling, which should take
          care of fine tuning.
        </p>
      </InfoSection>
      <form onSubmit={handleSubmit(onSubmit)}>
        {centralGameInstancesQuery.loading && <LoadingView />}
        {(centralGameInstancesQuery.data?.hostingServiceScalingParemeters || emptyArray).map((hsp) => {
          return (
            <InfoSection key={hsp.id} id={hsp.id} heading={`Service: ${hsp.name}`}>
              <FormGroup row={true}>
                <TextField type="number" inputRef={register} label="Min" name={`${hsp.id}|min`} defaultValue={hsp.min} />
              </FormGroup>
              <br />
              <FormGroup row={true}>
                <TextField type="number" inputRef={register} label="Max" name={`${hsp.id}|max`} defaultValue={hsp.max} />
              </FormGroup>
            </InfoSection>
          )
        })}
        <InfoSection id="expires" heading="Expires At">
          <FormGroup row={true}>
            <input type="datetime-local" placeholder="Expires At" name="expiresAtDate" defaultValue={expiresAtDate} ref={register} />
          </FormGroup>
          <p>
            <strong>NOTE</strong>: you can set this to a <strong>passed time</strong> to clear all values.
          </p>
        </InfoSection>
        <br />
        <Button variant="contained" color="primary" disabled={mutationResult.loading || centralGameInstancesQuery.loading} type="submit">
          {mutationResult.loading ? `Saving...` : `Save`}
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!snackBarSuccessMessage}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#558b2f",
          }}
          message={snackBarSuccessMessage}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Layout>
  )
}

export default ServiceScaling
